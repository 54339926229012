import * as React from 'react'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-shared/src/utils'
import { config } from '../../../../../config'
const tenantDirectoryName = config.tenant.code.toLowerCase()

const CommentHistoryContainer = MultitenantLoadable(
  () => import('./CommentHistoryContainer').catch((error) => console.log(error)),
  () =>
    import(
      '../../' +
        tenantDirectoryName +
        '/CommentHistory/CommentHistoryContainer'
    ).catch((error) => console.log(error))
)

const CommentHistoryPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./CommentHistoryPresentation').catch((error) => console.log(error)),
    () =>
      import(
        '../../' +
          tenantDirectoryName +
          '/CommentHistory/CommentHistoryPresentation'
      ).catch((error) => console.log(error))
  ),
  () => import('./formComponents').catch((error) => console.log(error)),
  () =>
    import(
      '../../' +
        tenantDirectoryName +
        '/CommentHistory/formComponents'
    ).catch((error) => console.log(error))
)

export default function CommentHistory(props) {
  return (
    <CommentHistoryContainer
      {...props}
      presentation={presentationProps => (
        <CommentHistoryPresentation {...presentationProps} />
      )}
    />
  )
}
